import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_VOTE_API_CALL_REQUEST, POST_VOTE_API_CALL_REQUEST } from "../redux/reducers/poll";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import _ from 'lodash';

const points = ["1", "2", "3", "5", "8", "13", "no_idea", "resign"];

class Poll extends Component {
  componentDidMount() {
    const { activeStory, fetchVote, wsConnect } = this.props;
    if (_.isObject(activeStory)) {
      console.log('getVote');
      fetchVote();
    }
  }

  componentDidUpdate(prevProps) {
    const { activeStory, fetchVote, wsConnect } = this.props;
    if (prevProps.activeStory == null && _.isObject(activeStory)) {
      console.log('getvote');
      fetchVote();
    }
  }

  render() {
    const { activeStory, fetchingVote, voteSelected, voteSelectedOnChange, postingVote, voteResult, lastActiveStory } = this.props;
    if (activeStory != null) {
      return (
        fetchingVote ? <CircularProgress />
          : <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" >
                {voteSelected == "" ? "Please vote!" : "Waiting for other votes!"
                }
              </Typography >
            </Grid >
            {
              points.map((point, id) => {
                const storyIsVoted = voteSelected == point;
                return (
                  <Grid key={id} item xs={4}>
                    <ToggleButton
                      value="check"
                      selected={storyIsVoted}
                      disabled={voteSelected != ""}
                      style={storyIsVoted ? { backgroundColor: '#7CFC00', color: '#000000' } : { backgroundColor: '#3f51b5', color: '#ffffff' }}
                      onChange={() => { voteSelectedOnChange(point) }}
                    >
                      Point: {point}
                      {postingVote ? <CircularProgress /> : (storyIsVoted ? <CheckIcon /> : null)}
                    </ToggleButton>
                  </Grid>
                );
              })
            }

            {!_.isEmpty(voteResult) ?
              <Grid item xs={12}>
                <Typography variant="h5" >
                  {"Vote Result:"}
                </Typography>
                {
                  _.isArray(voteResult) ?
                    voteResult.map((result, id) => {
                      return (
                        <Grid key={id} item xs={12}>
                          <Typography variant="h6" >
                            {(id + 1) + ". " + result.name + ": " + result.vote}
                          </Typography>
                        </Grid>
                      );
                    })
                    : null
                }
              </Grid>
              : null
            }
            {_.isObject(lastActiveStory) ?
              <Grid item xs={12}>
                <Typography variant="h6" >
                  {"Last Active Story: " + lastActiveStory.name}
                </Typography>
              </Grid>
              : null
            }
          </Grid >
      );
    } else {
      return (
        <div>
          {!_.isEmpty(voteResult) && _.isObject(lastActiveStory) ?
            <Grid item xs={12}>
              <Typography variant="h5" >
                {"Last Active Story: " + lastActiveStory.name}
              </Typography>
              <Typography variant="h5" >
                {"Vote Result:"}
              </Typography>
              {
                _.isArray(voteResult) ?
                  voteResult.map((result, id) => {
                    return (
                      <Grid key={id} item xs={12}>
                        <Typography variant="h6" >
                          {(id + 1) + ". " + result.name + ": " + result.vote}
                        </Typography>
                      </Grid>
                    );
                  })
                  : null
              }
            </Grid>
            : null
          }
        </div>);
    }
  }
}

const mapStateToProps = state => {
  return {
    activeStory: state.story.activeStory,
    fetchingVote: state.poll.fetchingVote,
    voteSelected: state.poll.voteSelected,
    postingVote: state.poll.postingVote,
    voteResult: state.websocket.voteResult,
    lastActiveStory: state.websocket.lastActiveStory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchVote: () => dispatch({ type: GET_VOTE_API_CALL_REQUEST }),
    voteSelectedOnChange: (voteSelected) => dispatch({ type: POST_VOTE_API_CALL_REQUEST, voteSelected }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Poll);