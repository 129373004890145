// action types
export const GET_STORY_API_CALL_REQUEST = "GET_STORY_API_CALL_REQUEST";
export const GET_STORY_API_CALL_SUCCESS = "GET_STORY_API_CALL_SUCCESS";
export const GET_STORY_API_CALL_FAILURE = "GET_STORY_API_CALL_FAILURE";

export const POST_ACTIVE_STORY_API_CALL_REQUEST = "POST_ACTIVE_STORY_API_CALL_REQUEST";
export const POST_ACTIVE_STORY_API_CALL_SUCCESS = "POST_ACTIVE_STORY_API_CALL_SUCCESS";
export const POST_ACTIVE_STORY_API_CALL_FAILURE = "POST_ACTIVE_STORY_API_CALL_FAILURE";

export const ACTIVE_STORY_SUBSCRIBE = "ACTIVE_STORY_SUBSCRIBE";

// reducer with initial state
const initialState = {
  fetchingStory: false,
  stories: [],
  activeStory: null,
  fetchStoryError: null,
  postingActiveStory: false,
  postActiveStoryError: null,
};

export default function stories(state = initialState, action) {
  switch (action.type) {
    case GET_STORY_API_CALL_REQUEST:
      return { ...state, fetchingStory: true, stories: [], activeStory: null, fetchStoryError: null };
    case GET_STORY_API_CALL_SUCCESS:
      return { ...state, fetchingStory: false, stories: action.stories, activeStory: action.activeStory };
    case GET_STORY_API_CALL_FAILURE:
      return { ...state, fetchingStory: false, stories: [], activeStory: null, fetchStoryError: action.fetchStoryError };
    case POST_ACTIVE_STORY_API_CALL_REQUEST:
      return { ...state, postingActiveStory: true, postActiveStoryError: null };
    case POST_ACTIVE_STORY_API_CALL_SUCCESS:
      return { ...state, postingActiveStory: false, };
    case POST_ACTIVE_STORY_API_CALL_FAILURE:
      return { ...state, postingActiveStory: false, postActiveStoryError: action.postActiveStoryError };
    default:
      return state;
  }
}