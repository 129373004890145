import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Toolbar, TextField, Typography, CircularProgress } from "@material-ui/core";
import { GET_USER_API_CALL_REQUEST, USERNAME_ON_CHANGE, POST_USER_LOGIN_API_CALL_REQUEST } from "../redux/reducers/header";

class Header extends Component {
  componentDidMount() {
    const { loggedIn, fetchUser } = this.props;
    if (!loggedIn) {
      console.log('check if logged in');
      fetchUser();
    }
  }
  render() {
    const { fetchingUser, loggedIn, username, fetchUserError, userNameOnChange, postUserLogin, postingUserLogin, postUserLoginError, wsStatus } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h4" >
            Scrum Poker&nbsp;&nbsp;
                    </Typography>
          {(fetchingUser || postingUserLogin) ? <div><CircularProgress /></div> :
            (loggedIn ? <Typography variant="h6">Welcome! {username}</Typography> :
              <div>
                <TextField label="Your Name" variant="outlined" onChange={userNameOnChange} value={username} />&nbsp;
                                <Button variant="contained" color="primary" onClick={postUserLogin}>Login</Button>
              </div>)}
          {(fetchUserError || postUserLoginError) && <p style={{ color: "red" }}>Uh oh - something went wrong!</p>}
          &nbsp;&nbsp;
          <Typography variant="h6" >
            Websocket Status: {wsStatus} &nbsp; {wsStatus == "Connecting" ? <CircularProgress /> : null} &nbsp;&nbsp;
          </Typography>
        </Toolbar>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    fetchingUser: state.header.fetchingUser,
    loggedIn: state.header.loggedIn,
    username: state.header.username,
    fetchUserError: state.header.fetchUserError,
    postingUserLogin: state.header.postingUserLogin,
    postUserLoginError: state.header.postUserLoginError,
    wsStatus: state.websocket.wsStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => dispatch({ type: GET_USER_API_CALL_REQUEST }),
    userNameOnChange: (e) => dispatch({ type: USERNAME_ON_CHANGE, username: e.target.value }),
    postUserLogin: () => dispatch({ type: POST_USER_LOGIN_API_CALL_REQUEST }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);