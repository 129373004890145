// action types
export const GET_USER_API_CALL_REQUEST = "GET_USER_API_CALL_REQUEST";
export const GET_USER_API_CALL_SUCCESS = "GET_USER_API_CALL_SUCCESS";
export const GET_USER_API_CALL_FAILURE = "GET_USER_API_CALL_FAILURE";
export const USERNAME_ON_CHANGE = "USERNAME_ON_CHANGE";
export const POST_USER_LOGIN_API_CALL_REQUEST = "POST_USER_LOGIN_API_CALL_REQUEST";
export const POST_USER_LOGIN_API_CALL_SUCCESS = "POST_USER_LOGIN_API_CALL_SUCCESS";
export const POST_USER_LOGIN_API_CALL_FAILURE = "POST_USER_LOGIN_API_CALL_FAILURE";

// reducer with initial state
const initialState = {
  fetchingUser: false,
  loggedIn: false,
  username: "",
  fetchUserError: null,
  postingUserLogin: false,
  postUserLoginError: null,
};

export default function header(state = initialState, action) {
  switch (action.type) {
    case GET_USER_API_CALL_REQUEST:
      return { ...state, fetchingUser: true, fetchUserError: null };
    case GET_USER_API_CALL_SUCCESS:
      return { ...state, fetchingUser: false, loggedIn: action.loggedIn, username: action.username };
    case GET_USER_API_CALL_FAILURE:
      return { ...state, fetchingUser: false, loggedIn: false, fetchUserError: action.fetchUserError };
    case USERNAME_ON_CHANGE:
      return { ...state, username: action.username };
    case POST_USER_LOGIN_API_CALL_REQUEST:
      return { ...state, postingUserLogin: true, postUserLoginError: null };
    case POST_USER_LOGIN_API_CALL_SUCCESS:
      return { ...state, postingUserLogin: false, loggedIn: true };
    case POST_USER_LOGIN_API_CALL_FAILURE:
      return { ...state, postingUserLogin: false, loggedIn: false, postUserLoginError: action.postUserLoginError };
    default:
      return state;
  }
}