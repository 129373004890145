import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";


import reducer from "./redux/reducers";
import saga from "./redux/sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create a redux store with our reducer above and middleware
let store = createStore(
  reducer,
  compose(applyMiddleware(sagaMiddleware))
);

// run the saga
sagaMiddleware.run(saga);

export default store;