import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_STORY_API_CALL_REQUEST, GET_STORY_API_CALL_SUCCESS, GET_STORY_API_CALL_FAILURE,
  POST_ACTIVE_STORY_API_CALL_REQUEST, POST_ACTIVE_STORY_API_CALL_SUCCESS, POST_ACTIVE_STORY_API_CALL_FAILURE
} from "../reducers/story";
import axios from "axios";

export default function* watcherSaga() {
  yield takeLatest(GET_STORY_API_CALL_REQUEST, fetchStories);
  yield takeLatest(POST_ACTIVE_STORY_API_CALL_REQUEST, postActiveStory);
}


function* fetchStories() {
  try {
    const response = yield call(function () {
      return axios({
        method: "get",
        url: "/api/v1/findAllStories",
        withCredentials: true,
      })
    });
    const stories = response.data;
    const activeStory = stories.find(story => story.active);

    // dispatch a success action to the store with the new dog
    yield put({ type: GET_STORY_API_CALL_SUCCESS, stories, activeStory });

  } catch (fetchStoryError) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_STORY_API_CALL_FAILURE, fetchStoryError });
  }
}

function* postActiveStory(action) {
  try {
    const response = yield call(function () {
      return axios({
        method: "post",
        url: `/api/v1/activeStory/${action.storyId}`,
        withCredentials: true,
      })
    });
    const result = response.data;
    const status = result.status;
    const errorReason = result.reason;
    if (status === "success") {
      yield put({ type: POST_ACTIVE_STORY_API_CALL_SUCCESS });
      yield put({ type: GET_STORY_API_CALL_REQUEST });
    }
    else
      yield put({ type: POST_ACTIVE_STORY_API_CALL_FAILURE, fetchStoryError: errorReason });

  } catch (fetchStoryError) {
    yield put({ type: POST_ACTIVE_STORY_API_CALL_FAILURE, fetchStoryError });
  }
}