import { combineReducers } from 'redux';
import header from './header';
import story from './story';
import poll from './poll';
import websocket from './websocket';

export default combineReducers({
  header,
  story,
  poll,
  websocket
});
