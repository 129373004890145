import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_STORY_API_CALL_REQUEST, POST_ACTIVE_STORY_API_CALL_REQUEST } from "../redux/reducers/story";
import { Typography, Grid, Button, Card, CircularProgress } from "@material-ui/core";

class Story extends Component {
  componentDidMount() {
    const { fetchingStory, onRequestStories } = this.props;
    if (!fetchingStory)
      onRequestStories();

  }
  render() {
    const { loggedIn, fetchingStory, stories, activeStory, onRequestStories, fetchStoryError, postActiveStory, postingActiveStory, postActiveStoryError } = this.props;
    if (loggedIn) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h6" >
              Stories
          </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" color="primary" onClick={onRequestStories} disabled={fetchingStory}>Reload</Button>
            {fetchingStory ? <div><CircularProgress /></div> : null}
          </Grid>

          { activeStory ?
            <Grid item xs={4}>
              <Typography variant="h4" >
                Active Story: {activeStory.name}
              </Typography>
            </Grid>
            :
            stories.map((story) => {
              return (
                <Grid item xs={6} key={story.id}>
                  <Card>
                    <div>{story.name}</div>
                    <div>Active: {story.active ? "true" : "false"}</div>
                    <Button variant="contained" color="primary" disabled={postingActiveStory} onClick={() => { postActiveStory(story.id) }}>Active</Button>
                    <Button variant="contained" color="secondary" onClick={onRequestStories}>Delete</Button>
                  </Card>
                </Grid>
              );
            })
          }
          {(fetchStoryError || postActiveStoryError) && <p style={{ color: "red" }}>Uh oh - something went wrong!</p>}
        </Grid >
      );
    }
    else return <div></div>;
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.header.loggedIn,
    fetchingStory: state.story.fetchingStory,
    stories: state.story.stories,
    activeStory: state.story.activeStory,
    fetchStoryError: state.story.fetchStoryError,
    postingActiveStory: state.story.postingActiveStory,
    postActiveStoryError: state.story.postActiveStoryError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRequestStories: () => dispatch({ type: GET_STORY_API_CALL_REQUEST }),
    postActiveStory: (storyId) => dispatch({ type: POST_ACTIVE_STORY_API_CALL_REQUEST, storyId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Story);