import { select, takeLatest, call, put } from "redux-saga/effects";
import {
  GET_USER_API_CALL_REQUEST, GET_USER_API_CALL_SUCCESS, GET_USER_API_CALL_FAILURE,
  POST_USER_LOGIN_API_CALL_REQUEST, POST_USER_LOGIN_API_CALL_SUCCESS, POST_USER_LOGIN_API_CALL_FAILURE
} from "../reducers/header";
import { WS_CLIENT_CONNECT_REQUEST } from '../reducers/websocket';
import axios from "axios";
import _ from "lodash";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(GET_USER_API_CALL_REQUEST, fetchUser);
  yield takeLatest(POST_USER_LOGIN_API_CALL_REQUEST, postUserLogin);
}


// worker saga: makes the api call when watcher saga sees the action
function* fetchUser() {
  try {
    const response = yield call(function () {
      return axios({
        method: "get",
        url: "/api/v1/user",
        withCredentials: true,
      })
    });
    const username = response.data;
    const loggedIn = !_.isEmpty(username);

    yield put({ type: GET_USER_API_CALL_SUCCESS, username, loggedIn });
    yield put({ type: WS_CLIENT_CONNECT_REQUEST });

  } catch (fetchUserError) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_USER_API_CALL_FAILURE, fetchUserError });
  }
}

function* postUserLogin() {
  try {
    const username = yield select(state => state.header.username);
    yield call(function () {
      return axios({
        method: "post",
        url: "/api/v1/user/login",
        data: {
          name: username
        },
        withCredentials: true,
      })
    });
    yield put({ type: POST_USER_LOGIN_API_CALL_SUCCESS });
    yield put({ type: GET_USER_API_CALL_REQUEST });

  } catch (postUserLoginError) {
    // dispatch a failure action to the store with the error
    yield put({ type: POST_USER_LOGIN_API_CALL_FAILURE, postUserLoginError });
  }
}