import { all } from 'redux-saga/effects';
import header from './header';
import story from './story';
import poll from'./poll';
import websocket from './webscoket';

function* rootSaga() {
  yield all([
    header(),
    story(),
    poll(),
    websocket(),
  ]);
}

export default rootSaga;
