import { select, takeLatest, call, put } from "redux-saga/effects";
import store from '../../store';
import { GET_STORY_API_CALL_REQUEST, GET_STORY_API_CALL_SUCCESS } from '../reducers/story';
import { DELETE_VOTE_API_CALL_REQUEST } from '../reducers/poll';
import {
  WS_CLIENT_CONNECT_REQUEST, WS_CLIENT_CONNECT_SUCCESS, WS_CLIENT_CONNECT_FAILURE,
  WS_CLIENT_VOTE_RESULT_SUBSCRIBE, WS_CLIENT_VOTE_RESULT_RECEIVED, WS_CLIENT_ACTIVE_STORY_SUBSCRIBE
} from "../reducers/websocket";
import _ from "lodash";
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs'

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(WS_CLIENT_CONNECT_REQUEST, websocketConnect);
  yield takeLatest(WS_CLIENT_VOTE_RESULT_SUBSCRIBE, subscribeVoteResult);
  yield takeLatest(WS_CLIENT_ACTIVE_STORY_SUBSCRIBE, subscribeActiveStory);
}

// worker saga: makes the api call when watcher saga sees the action
function* websocketConnect() {
  try {
    const socket = new SockJS("/voteRoom");
    const stompClient = Stomp.over(socket);
    stompClient.connect({}, function connect() {
      console.log('Connected to chat server');
      store.dispatch({ type: WS_CLIENT_CONNECT_SUCCESS, stompClient });
      store.dispatch({ type: WS_CLIENT_VOTE_RESULT_SUBSCRIBE });
      store.dispatch({ type: WS_CLIENT_ACTIVE_STORY_SUBSCRIBE });
    });

    const onClose = () => {
      console.log('Disconnected from server');
      store.dispatch({ type: WS_CLIENT_CONNECT_FAILURE, wsConnectError: "Disconnected" });
    }
    socket.onclose = onClose
    socket.onerror = onClose
    stompClient.onclose({}, onclose);
  } catch (wsConnectError) {
    yield put({ type: WS_CLIENT_CONNECT_FAILURE, wsConnectError });
  }
}

function* subscribeVoteResult() {
  const stompClient = yield select(state => state.websocket.stompClient);
  stompClient.subscribe('/vote/voteResult', (data) => {
    const lastActiveStory = store.getState().story.activeStory ? store.getState().story.activeStory : store.getState().websocket.lastActiveStory;
    console.log('lastActiveStory = ' + lastActiveStory);
    const voteResult = JSON.parse(data.body);
    store.dispatch({ type: WS_CLIENT_VOTE_RESULT_RECEIVED, voteResult, lastActiveStory });
    store.dispatch({ type: GET_STORY_API_CALL_REQUEST });
    store.dispatch({ type: DELETE_VOTE_API_CALL_REQUEST });
  });
}

function* subscribeActiveStory() {
  const stompClient = yield select(state => state.websocket.stompClient);
  stompClient.subscribe('/vote/activeStory', (data) => {
    const stories = JSON.parse(data.body);
    const activeStory = stories.find(story => story.active);
    console.log('activeStory = ' + activeStory);
    store.dispatch({ type: GET_STORY_API_CALL_SUCCESS, stories, activeStory });
  });
}
