import { select, takeLatest, call, put } from "redux-saga/effects";
import {
  GET_VOTE_API_CALL_REQUEST, GET_VOTE_API_CALL_SUCCESS, GET_VOTE_API_CALL_FAILURE,
  POST_VOTE_API_CALL_REQUEST, POST_VOTE_API_CALL_SUCCESS, POST_VOTE_API_CALL_FAILURE,
  DELETE_VOTE_API_CALL_REQUEST,
} from "../reducers/poll";
import axios from "axios";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(GET_VOTE_API_CALL_REQUEST, fetchVote);
  yield takeLatest(POST_VOTE_API_CALL_REQUEST, postVote);
  yield takeLatest(DELETE_VOTE_API_CALL_REQUEST, deleteVote);
}


// worker saga: makes the api call when watcher saga sees the action
function* fetchVote() {
  try {
    const response = yield call(function () {
      return axios({
        method: "get",
        url: "/api/v1/vote",
        withCredentials: true,
      })
    });
    const voteSelected = response.data;
    // dispatch a success action to the store with the new dog
    yield put({ type: GET_VOTE_API_CALL_SUCCESS, voteSelected });

  } catch (fetchVoteError) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VOTE_API_CALL_FAILURE, fetchVoteError });
  }
}

function* postVote() {
  try {
    const voteSelected = yield select(state => state.poll.voteSelected);
    yield call(function () {
      return axios({
        method: "post",
        url: "/api/v1/addVote",
        data: {
          vote: voteSelected
        },
        withCredentials: true,
      })
    });
    yield put({ type: POST_VOTE_API_CALL_SUCCESS });

  } catch (postVoteError) {
    // dispatch a failure action to the store with the error
    yield put({ type: POST_VOTE_API_CALL_FAILURE, postVoteError });
  }
}

function* deleteVote() {
  try {
    yield call(function () {
      return axios({
        method: "delete",
        url: "/api/v1/deleteVote",
        withCredentials: true,
      })
    });

  } catch (deleteVoteError) {
    // dispatch a failure action to the store with the error
    console.log('deleteVoteError ', deleteVoteError);
  }
}