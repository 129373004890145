// action types
export const GET_VOTE_API_CALL_REQUEST = "GET_VOTE_API_CALL_REQUEST";
export const GET_VOTE_API_CALL_SUCCESS = "GET_VOTE_API_CALL_SUCCESS";
export const GET_VOTE_API_CALL_FAILURE = "GET_VOTE_API_CALL_FAILURE";
export const POST_VOTE_API_CALL_REQUEST = "POST_VOTE_API_CALL_REQUEST";
export const POST_VOTE_API_CALL_SUCCESS = "POST_VOTE_API_CALL_SUCCESS";
export const POST_VOTE_API_CALL_FAILURE = "POST_VOTE_API_CALL_FAILURE";
export const DELETE_VOTE_API_CALL_REQUEST = "DELETE_VOTE_API_CALL_REQUEST";

// reducer with initial state
const initialState = {
  fetchingVote: false,
  voteSelected: "",
  fetchVoteError: null,
  postingVote: false,
  postVoteError: null,
};

export default function poll(state = initialState, action) {
  switch (action.type) {
    case GET_VOTE_API_CALL_REQUEST:
      return { ...state, fetchingVote: true, fetchVoteError: null };
    case GET_VOTE_API_CALL_SUCCESS:
      return { ...state, fetchingVote: false, voteSelected: action.voteSelected };
    case GET_VOTE_API_CALL_FAILURE:
      return { ...state, fetchingVote: false, voteSelected: {}, fetchVoteError: action.fetchVoteError };
    case POST_VOTE_API_CALL_REQUEST:
      return { ...state, postingVote: true, voteSelected: action.voteSelected, postVoteError: null };
    case POST_VOTE_API_CALL_SUCCESS:
      return { ...state, postingVote: false };
    case POST_VOTE_API_CALL_FAILURE:
      return { ...state, postingVote: false, voteSelected: "", postVoteError: action.postVoteError };
    case DELETE_VOTE_API_CALL_REQUEST:
      return { ...state,voteSelected: "" };
    default:
      return state;
  }
}