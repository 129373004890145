import React, { Component } from "react";
import { connect } from "react-redux";
import Story from './components/Story';
import Header from './components/Header';
import Poll from './components/Poll';

class App extends Component {
  render() {
    const { loggedIn } = this.props;
    return (
      <div className="App">
        <Header />
        {loggedIn ?
          <div>
            <Story />
            <Poll />
          </div> : null
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.header.loggedIn,

  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);