// action types
export const WS_CLIENT_CONNECT_REQUEST = "WS_CLIENT_CONNECT_REQUEST";
export const WS_CLIENT_CONNECT_SUCCESS = "WS_CLIENT_CONNECT_SUCCESS";
export const WS_CLIENT_CONNECT_FAILURE = "WS_CLIENT_CONNECT_FAILURE";
export const WS_CLIENT_VOTE_RESULT_SUBSCRIBE = "WS_CLIENT_VOTE_RESULT_SUBSCRIBE";
export const WS_CLIENT_VOTE_RESULT_RECEIVED = "WS_CLIENT_VOTE_RESULT_RECEIVED";
export const WS_CLIENT_ACTIVE_STORY_SUBSCRIBE = "WS_CLIENT_ACTIVE_STORY_SUBSCRIBE";

// reducer with initial state
const initialState = {
  voteResult: [],
  wsStatus: "Disconnected",
  wsConnectError: null,
  stompClient: null,
  lastActiveStory: "",
};

export default function websocket(state = initialState, action) {
  switch (action.type) {
    case WS_CLIENT_VOTE_RESULT_RECEIVED:
      return { ...state, voteResult: action.voteResult, lastActiveStory: action.lastActiveStory };
    case WS_CLIENT_CONNECT_REQUEST:
      return { ...state, wsStatus: "Connecting", wsConnectError: null };
    case WS_CLIENT_CONNECT_SUCCESS:
      return { ...state, wsStatus: "Connected", stompClient: action.stompClient };
    case WS_CLIENT_CONNECT_FAILURE:
      return { ...state, wsStatus: "Disconnected", wsConnectError: action.wsConnectError };
    default:
      return state;
  }
}